<template>
    <section class="pr--main-section">
        <div class="container pr--main-container pr--header-padding">
            <div :class="`pr--main-middle ${system.sidebar.closed ? '__details' : ''} __${responsible.type()}`">
                <div class="pr--main-middle--left">
                    <slot name="left" />
                </div>
                <div class="pr--main-middle--center">
                    <slot name="center" />
                </div>
                <div class="pr--main-middle--right">
                    <slot name="right" />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import useResponsible from '@/hooks/responsible';
import { useStoreSystem } from '@/stores/system';

const system = useStoreSystem();
const responsible = useResponsible();
</script>
