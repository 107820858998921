<template>
    <div v-sticky="{ isSticky: true }" class="h-100">
        <!-- d-flex flex-column justify-content-between style="height: calc(100vh - 54px - 1rem)" -->
        <nav v-if="!system.sidebar.closed" aria-label="Main navigation" class="h-100">
            <div class="h-100 pr--nav-left--container justify-content-start">
                <div v-if="dataIndex.data.sidebar !== null" class="pr--sticky-container">
                    <LayoutSidebarMenu />
                </div>
<!--                <div class="pr&#45;&#45;sidebar-section">-->
<!--                    <ul :class="`nav nav&#45;&#45;middle &#45;&#45;block p-0`">-->
<!--                        <li>-->
<!--                            <button v-if="!system.sidebar.closed" class="btn btn-light borderless btn-block d-flex align-items-center justify-content-between position-relative rounded-0" @click="changeSidebarClosed">-->
<!--                                <IconFontAwesome :name="'chevron-left'" class="icon-md mr-2" />-->
<!--                                <span>-->
<!--                                    Свернуть-->
<!--                                </span>-->
<!--                            </button>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
        </nav>
        <nav v-if="system.sidebar.closed" aria-label="Main navigation" class="h-100">
            <div class="h-100 pr--nav-left--container justify-content-start">
                <div class="pr--sticky-container">
                    <LayoutSidebarMenuClosed />
                </div>
<!--                <div class="pr&#45;&#45;sidebar-section">-->
<!--                    <ul :class="`nav nav&#45;&#45;middle &#45;&#45;block p-0`">-->
<!--                        <li>-->
<!--                            <button v-if="system.sidebar.closed" class="btn btn-light borderless btn-block d-flex align-items-center justify-content-center position-relative rounded-0" @click="changeSidebarClosed">-->
<!--                                <IconFontAwesome :name="'chevron-right'" class="icon-md" />-->
<!--                            </button>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
        </nav>
<!--        <div>-->
<!--            <div class="pr&#45;&#45;sidebar-section">-->
<!--                <ul :class="`nav nav&#45;&#45;middle &#45;&#45;block p-0`">-->
<!--                    <li>-->
<!--                        <button v-if="system.sidebar.closed" class="btn btn-light borderless btn-block d-flex align-items-center justify-content-center position-relative rounded-0" @click="changeSidebarClosed">-->
<!--                            <IconFontAwesome :name="'chevron-right'" class="icon-md" />-->
<!--                        </button>-->
<!--                        <button v-if="!system.sidebar.closed" class="btn btn-light borderless btn-block d-flex align-items-center justify-content-between position-relative rounded-0" @click="changeSidebarClosed">-->
<!--                            <IconFontAwesome :name="'chevron-left'" class="icon-md mr-2" />-->
<!--                            <span>-->
<!--                                Свернуть-->
<!--                            </span>-->
<!--                        </button>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script setup lang="ts">
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
import LayoutSidebarMenu from '@/components/Layout/Sidebar/LayoutSidebarMenu.vue';
import LayoutSidebarMenuClosed from '@/components/Layout/Sidebar/LayoutSidebarMenuClosed.vue';
import { useStoreAppDataIndex } from '@/stores/app/data';
import { useStoreSystem } from '@/stores/system';

const dataIndex = useStoreAppDataIndex();
const system = useStoreSystem();

function changeSidebarClosed() {
    system.sidebar.closed = !system.sidebar.closed;
}

</script>
